<template>
  <ModalWindow :lock-body-on-open="false" class="locale-modal" @closed="toggleShow">
    <div class="locale-content">
      <div class="locale-title">Preferences</div>
      <label for="locale-select">Choose Country/Language</label>
      <select id="locale-select" v-model="matchedLocale" @click.prevent @change="selectLocale($event)">
        <option
          v-for="localeItem in locales"
          :key="localeItem.code"
          :value="localeItem"
          :selected="localeItem.code === matchedLocale.code"
          :class="{ selected: localeItem.code === matchedLocale.code, 'flex-inline': true }"
        >
          {{ reverseTextWithKey(localeItem.fullName, '-') }}
        </option>
      </select>
      <CustomButton
        style-name="primary"
        style-color="black"
        class="save-button"
        style="margin-top: auto"
        @click-button="saveSelections"
        >Save</CustomButton
      >
    </div>
  </ModalWindow>
</template>

<script setup lang="ts">
import { useNavStore } from '~/store/nav';
import { CookieKeys, deleteCookie } from '~/util/storage';
// https://v8.i18n.nuxtjs.org/guide/lang-switcher
const { locale, locales, localeProperties, setLocale }: any = useI18n(); // setLocaleCookie

const navStore = useNavStore();
const currentLocale = ref<string>(locale.value);
const currentRegion = ref<string>(localeProperties.value?.regionCode);

const toggleShow = () => {
  navStore.setLocaleModalOpen(!navStore.localeModalOpen);
};

const matchedLocale = computed(() => {
  return (locales?.value as any)?.find((i: any) => i.code === locale.value);
});

async function saveSelections() {
  if (currentLocale.value === locale.value) {
    toggleShow();
    return;
  }
  if (currentRegion.value !== localeProperties.value?.regionCode) {
    deleteCookie(CookieKeys.User);
  }
  // TODO - check if we need this
  // setLocaleCookie(currentLocale.value);
  await setLocale(currentLocale.value);
  deleteCookie(CookieKeys.Cart);
  toggleShow();
  window.location.reload();
}

function reverseTextWithKey(text: string, key: string) {
  const parts = text.split(key);
  const reversedParts = parts.map((part) => part.trim()).reverse();
  const reversedText = reversedParts.join(' ' + key + ' ');
  return reversedText;
}

/**
 * @param index
 * @param id either id or for <select> the HTMLOptionsCollection options array
 * @param isSelect
 */
function selectLocale(event: any) {
  const selectOptions = event?.target?.options;
  for (let i = 0; i < selectOptions?.length; i++) {
    if (selectOptions[i]?.selected) {
      currentLocale.value = selectOptions[i]?._value.code;
      currentRegion.value = selectOptions[i]?._value.regionCode;
    }
  }
}
</script>

<style lang="scss">
.locale-modal .modal-content {
  border-radius: 4px;
  max-width: calc(100vw - 2rem); // mob 16px padding
  @include local-mixins.desktop {
    max-width: 21.5rem;
    min-height: 340px;
  }
}
</style>

<style lang="scss" scoped>
.save-button {
  font-size: 1rem;
  padding: 5px 10px;
}
label {
  font-size: 1rem;
  line-height: 125%;
}
.locale-title {
  font-size: 1.25rem;
  line-height: 125%;
  font-weight: 500;
  margin-bottom: 10px;
}
.locale-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}
</style>
